import React from "react"
import styled from "styled-components"

import { Container } from "../global"

const Footer = () => (
  <FooterWrapper id="footer">
    <FooterColumnContainer>
      {/* <FooterColumn>
        <span>Features</span>
        Eventually Clickable Content
        <ul>
          <li>Automation</li>
          <li>Rewards</li>
        </ul>
      </FooterColumn> */}
    </FooterColumnContainer>
    <BrandContainer>
      <Logo>Under Development</Logo>
    </BrandContainer>
    <BrandContainer>
      <Copyright>Copyright 2021 ChecksOn.Me</Copyright>
    </BrandContainer>
  </FooterWrapper>
)

const FooterWrapper = styled.footer`
  background-color: white;
  margin: 80px 0 0;
  padding: 0 0 80px;
`

const Logo = styled.div`
  font-family: ${(props) => props.theme.font.extrabold};
  ${(props) => props.theme.font_size.regular};
  color: ${(props) => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  text-decoration: none;
  outline: 0px;
`

const BrandContainer = styled(Container)`
  position: relative;
  padding-top: 10px;
  display: flex;
  align-items: flex-end;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
  }
`
const FooterColumnContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 32px;
  justify-content: start;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`
// const FooterColumn = styled.div`
//   span {
//     font-size: 16px;
//     font-family: ${(props) => props.theme.font.bold};
//     color: ${(props) => props.theme.color.primary};
//   }
//   ul {
//     list-style: none;
//     margin: 16px 0;
//     padding: 0;
//     color: ${(props) => props.theme.color.black.regular};
//     li {
//       margin-bottom: 12px;
//       font-family: ${(props) => props.theme.font.normal};
//       font-size: 15px;
//     }
//   }
// `

const Copyright = styled.div`
  font-family: ${(props) => props.theme.font.extrabold};
  ${(props) => props.theme.font_size.xsmall};
  color: ${(props) => props.theme.color.black.light};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  text-decoration: none;
  outline: 0px;
`

export default Footer
