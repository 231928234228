import React, {useState} from "react"
import styled from "styled-components"

import { Container, Section } from "../global"

const GetStarted = () => {
  let [isSent, setIsSent] = useState(false);
  function displayThankYou() {
    // e.preventDefault()
    setIsSent(true)
    setTimeout(function() {
      setIsSent(false)
      document.getElementById("name").value=''
      document.getElementById("email").value=''
      document.getElementById("message").value=''
    }, 5000);
  }
    
  return (
    <div>
      <StyledSection>
      <GetStartedContainer>
        <div id="contact">
        {isSent ? 
          <GetStartedTitle>Thank you, we'll get back to you soon!</GetStartedTitle>
          : <GetStartedTitle>Like our idea?</GetStartedTitle>}
        </div>
        
        <form id="contact" method="post" onSubmit={() => displayThankYou()}
        action="https://getform.io/f/3e13a7d9-d806-4399-b6ef-fcd0dfb8f296" target='popup'>
        <div className="fields">
            <div className="field">
              <label htmlFor="name">Name</label><br/>
              <input type="text" name="name" id="name" minlength="2" required/>
            </div>
            <div className="field">
              <label htmlFor="email">Email</label><br/>
              <input type="email" name="email" id="email" minlength="2" required/>
            </div>
            <div className="field">
              <label htmlFor="message">Message</label><br/>
              <textarea name="message" id="message" rows="4" minlength="3" required></textarea>
            </div>
          </div>
        <TryItButton type="submit">
          Contact Us
        </TryItButton>
        </form>
      </GetStartedContainer>
        <LinksContainer>
        <LinkButton>
          <a href="http://ashlee.nyc" target="_blank" rel="noopener noreferrer">ashlee.nyc</a>
        </LinkButton>
          Meet the team!
        <LinkButton>
          <a href="http://kali.nyc" target="_blank" rel="noopener noreferrer">kali.nyc</a>
        </LinkButton>
        </LinksContainer>
    </StyledSection>
    <iframe name="popup" title="ChecksOn.Me" height={0} width={0}></iframe>
    </div>
  )
}

export default GetStarted

const StyledSection = styled(Section)`
  background-color: ${(props) => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`

const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
`

const GetStartedTitle = styled.h3`
  margin: 0 auto 32px;
  text-align: center;
`

const TryItButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`
const LinksContainer = styled(Container)`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 40px 0 40px;
`

const LinkButton = styled.button`
  font-weight: 500;
  font-size: 10px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.theme.color.accentLT};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`